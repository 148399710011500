import React from "react";
import { exm_file, warning, warning_2, warning_3 } from "../texts/fill";
import { useExportFile } from "../hook/use_export_file";

const DownloadExample = () => {
    const { handleExport } = useExportFile();
    return (
        <div>
            <div class="alert alert-danger" role="alert">
                <div style={{ fontWeight: 'bold' }}>
                    {warning_2}
                </div>
                {warning_3}
            </div>
            <div style={{ display: 'flex', position: 'relative', alignItems: 'center', justifyContent: 'flex-start' }}>
                <button onClick={handleExport} className="btn btn-info m-3" style={{ height: '80px' }}>
                    {exm_file}
                    <img src="downloads.png" alt="" style={{  height: '35px', paddingTop: '9px' }} />
                </button>
                <div className="alert alert-warning" style={{ display: 'flex', width: '311px', justifycontent: 'space-between'}} >
                    {warning}
                    <img src="warning.png" alt="" style={{ width: '35px', height: '35px', paddingTop: '9px' }} />
                </div>
            </div>
        </div>
    )
};

export default DownloadExample;